import gql from 'graphql-tag';

const GET_ALL_ORDERS = gql`
  query getAllOrders(
    $pageRequest: Int
    $pageSize: Int
    $orderNumber: Int
    $orderClient: String
    $orderStatus: String
    $orderDeliveryCode: String
    $paymentStatus: String
    $sellerId: Int
    $isLater: Boolean
    $product: String
    $invoiceNumber: String
    $startDate: String
    $endDate: String
    $orderStatusList: [String]
    $orderSellerUuid: String
  ) {
    getAllOrders(
      page: $pageRequest
      size: $pageSize
      id: $orderNumber
      customer: $orderClient
      orderStatus: $orderStatus
      paymentStatus: $paymentStatus
      deliveryCode: $orderDeliveryCode
      sellerId: $sellerId
      isLater: $isLater
      product: $product
      invoiceNumber: $invoiceNumber
      startDate: $startDate
      endDate: $endDate
      orderStatusList: $orderStatusList
      orderSellerUuid: $orderSellerUuid
    ) {
      totalPages
      number
      content {
        customerName
        hasVariableWeight
        orderDate
        deliveryExpectedDate
        orderId
        seller {
          id
          name
        }
        sellerSetId
        sellerSetTotalValue
        status
        deliveryCode
        paymentProcessed
      }
    }
  }
`;

const GET_PENDING_ORDERS = gql`
  query getPendingOrders(
    $pageRequest: Int
    $pageSize: Int
    $orderNumber: Int
    $orderClient: String
    $orderStatus: String
    $orderStatusList: [String]
    $sellerId: Int
    $startDate: String
    $endDate: String
  ) {
    getPendingOrders(
      page: $pageRequest
      size: $pageSize
      id: $orderNumber
      customer: $orderClient
      orderStatus: $orderStatus
      orderStatusList: $orderStatusList
      sellerId: $sellerId
      startDate: $startDate
      endDate: $endDate
    ) {
      totalPages
      number
      content {
        customerName
        hasVariableWeight
        orderDate
        orderId
        seller {
          id
          name
        }
        sellerSetId
        sellerSetTotalValue
        status
      }
    }
  }
`;

const GET_CHARGEBACK = gql`
  query getChargeback($orderId: ID!) {
    getChargeback(orderId: $orderId) {
      totalAmountAvailable
    }
  }
`;

const GET_ORDER = gql`
  query getOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      id
      uuid
      ip
      totalAmount
      betaFeatures
      itemsAmount
      itemsDiscountAmount
      totalDiscountAmount
      shippingAmount
      shippingDiscountAmount
      couponDiscountAmount
      step
      userAgent
      utmCampaign
      utmMedium
      utmSource
      visitor
      createdAt
      updatedAt
      note
      hasPendingRefund
      customer {
        id
        customerInfo {
          id
          isFraudster
          iderp
        }
        document
        stateRegistration
        email
        mobilePhone
        name
        owner
        phone
        createdAt
        updatedAt
      }
      billingAddress {
        id
        addressId
        deliveryId
        establishmentId
        address
        city
        complement
        country
        name
        neighborhood
        number
        postalCode
        state
        createdAt
        updatedAt
      }
      shippingAddress {
        id
        addressId
        deliveryId
        establishmentId
        postalCode
        address
        city
        complement
        country
        name
        neighborhood
        number
        state
        createdAt
        updatedAt
      }
      orderSellers {
        id
        orderId
        uuid
        shortCode
        seller {
          id
          name
          document
          isNewIntegration
        }
        itemsAmount
        itemsDiscountAmount
        shippingAmount
        shippingDiscountAmount
        sellerAmount
        owner
        step
        deliveries {
          id
          orderId
          name
          step
          carrierId
          deliveryDate
          deliveryExpectedDate
          itemsAmount
          itemsDiscountAmount
          shippingAmount
          shippingDiscountAmount
          shippingTime
          cancellationReason
          invoiceIssuedDate
          invoiceNumber
          invoiceSeries
          invoiceUrl
          trackingCode
          type
          ownerType
          deliveryPeriods
          collectorName
          items {
            id
            deliveryId
            category
            commission
            ean
            name
            quantity
            sellPrice
            sellPriceWithoutDiscount
            totalDiscount
            unitPrice
            sellerUnitPrice
            unitPriceWithoutDiscount
            adminSellPrice
            sellerSellPrice
            sellerCode
            variableWeight
            weight
            variableWeightInformed
            informedTotalWeight
            createdAt
            updatedAt
            history {
              id
              oldQuantity
              newQuantity
              oldSellPrice
              oldUnitPrice
              oldSellerUnitPrice
              oldTotalDiscount
              oldWeight
              newWeight
              sellPrice
              unitPrice
              variableWeight
              createdAt
              isOriginal
              orderItemStatus
              login
              paymentProcessedStatus
            }
            hasHistory
            discounts {
              id
              discountId
              discountType
              orderItemId
              amount
              name
              createdAt
              updatedAt
            }
            basePrice {
              type
            }
            variation {
              id
              referenceCode
            }
          }
          laasQuote {
            laasOrderId
            deliveryCode
          }
          pickupAddress {
            id
            addressId
            deliveryId
            establishmentId
            address
            city
            complement
            country
            name
            neighborhood
            number
            postalCode
            state
            createdAt
            updatedAt
          }
          steps {
            active
            name
            date
            login
          }
          audit {
            id
            stepDate
            observation
            status
            login
            createdAt
          }
        }
        subsidies {
          id
          discountId
          costCenterId
          orderSellerId
          amount
          type
          discount {
            id
            name
            isCoupon
            couponCode
          }
        }
      }
      paymentProcessed
      paymentProcessedMotive
      payments {
        id
        orderId
        chargeId
        type
        bankSlipUrl
        creditCardBin
        creditCardToken
        currentStatus
        redemptionCode
        creditCardLastNumbers
        bankSlipExpirationDate
        creditCardExpirationDate
        totalAmount
        installments
        createdAt
        updatedAt
        paymentStatusHistory {
          id
          orderId
          orderPaymentId
          isCurrent
          status
          createdAt
          updatedAt
        }
        payInProcess
      }
      paymentsHistory {
        id
        orderId
        orderDeliveryId
        automaticRefund
        paymentId
        type
        totalAmount
        transactionType
        login
        originPaymentHistory
        createdAt
      }
    }
  }
`;

const GET_ORDER_BY_SELLER_MONTHLY_PREVISION = gql`
  query getOrdersBySellerMonthlyPrevision($sellerId: Int!) {
    getOrdersBySellerMonthlyPrevision(sellerId: $sellerId) {
      monthlyAmountPrevision
      monthlyDatePrevision
      monthlyStartDate
      monthlyEndDate
    }
  }
`;

const GET_ORDER_BY_SELLER_PERFORMANCE = gql`
  query getOrdersBySellerPerformance($sellerId: Int!, $startDate: String, $endDate: String) {
    getOrdersBySellerPerformance(sellerId: $sellerId, startDate: $startDate, endDate: $endDate) {
      averageTicketPrice
      sellAmount
      sellAmountPrevision
      sellDone
      sellQuantity
      sellStatusDelayed
      sellStatusPending
    }
  }
`;

const GET_ORIGINAL_ORDER = gql`
  query getOriginalOrder($orderId: ID!) {
    getOriginalOrder(orderId: $orderId) {
      id
      betaFeatures
      error
      ip
      itemsAmount
      itemsDiscountAmount
      totalAmount
      totalDiscountAmount
      shippingAmount
      shippingDiscountAmount
      step
      userAgent
      utmCampaign
      utmMedium
      utmSource
      visitor
      createdAt
      updatedAt
      note
      customer {
        id
        customerInfo {
          id
          isFraudster
          iderp
        }
        document
        stateRegistration
        email
        mobilePhone
        name
        owner
        phone
        createdAt
        updatedAt
      }
      billingAddress {
        id
        addressId
        deliveryId
        establishmentId
        address
        city
        complement
        country
        name
        neighborhood
        number
        postalCode
        state
        createdAt
        updatedAt
      }
      shippingAddress {
        id
        addressId
        deliveryId
        establishmentId
        postalCode
        address
        city
        complement
        country
        name
        neighborhood
        number
        state
        createdAt
        updatedAt
      }
      orderSellers {
        id
        orderId
        seller {
          id
          name
        }
        itemsAmount
        itemsDiscountAmount
        shippingAmount
        sellerAmount
        shippingDiscountAmount
        sellerAmount
        owner
        step
        deliveries {
          id
          orderId
          name
          step
          deliveryDate
          itemsAmount
          itemsDiscountAmount
          shippingAmount
          shippingDiscountAmount
          shippingTime
          cancellationReason
          invoiceIssuedDate
          invoiceNumber
          invoiceSeries
          invoiceUrl
          trackingCode
          type
          items {
            id
            deliveryId
            category
            commission
            ean
            name
            quantity
            sellPrice
            sellPriceWithoutDiscount
            unitPriceWithoutDiscount
            adminSellPrice
            sellerSellPrice
            totalDiscount
            unitPrice
            sellerUnitPrice
            sellerCode
            variableWeight
            weight
            variableWeightInformed
            informedTotalWeight
            createdAt
            updatedAt
            history {
              id
              oldQuantity
              newQuantity
              sellPrice
              unitPrice
              variableWeight
              createdAt
              isOriginal
              orderItemStatus
              login
              paymentProcessedStatus
            }
            hasHistory
            discounts {
              id
              discountId
              discountType
              orderItemId
              amount
              name
              createdAt
              updatedAt
            }
            basePrice {
              type
            }
            variation {
              id
              referenceCode
            }
          }
          pickupAddress {
            id
            addressId
            deliveryId
            establishmentId
            address
            city
            complement
            country
            name
            neighborhood
            number
            postalCode
            state
            createdAt
            updatedAt
          }
          steps {
            active
            name
            date
            login
          }
          audit {
            id
            stepDate
            observation
            status
            login
            createdAt
          }
        }
        subsidies {
          id
          costCenterId
          orderSellerId
          amount
          type
          discount {
            id
            name
            isCoupon
            couponCode
          }
        }
      }
      payments {
        id
        orderId
        chargeId
        type
        bankSlipUrl
        creditCardBin
        creditCardToken
        currentStatus
        redemptionCode
        creditCardLastNumbers
        bankSlipExpirationDate
        creditCardExpirationDate
        totalAmount
        installments
        createdAt
        updatedAt
        paymentStatusHistory {
          id
          orderId
          orderPaymentId
          isCurrent
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export {
  GET_ALL_ORDERS,
  GET_PENDING_ORDERS,
  GET_ORDER,
  GET_ORDER_BY_SELLER_MONTHLY_PREVISION,
  GET_ORIGINAL_ORDER,
  GET_ORDER_BY_SELLER_PERFORMANCE,
  GET_CHARGEBACK,
};
