import { useState } from 'react';
import { Modal } from 'ifoodshop-react-ui';

import FormatHelper from '@helpers/FormatHelper';

import ModalDiscounts from '../OrderResume/ModalDiscounts';

const OrderResume = ({ orderChild, isSeller }) => {
  const [modal, setModal] = useState(null);

  function seeDiscount(e, index) {
    e.preventDefault();

    setModal(
      <Modal onCloseAction={() => setModal(null)} className="order-detail__modal-discount">
        <ModalDiscounts subsidies={orderChild[index]?.subsidies} />
      </Modal>
    );
  }

  function handleDiscountSeller(
    itemsAmount,
    itemsDiscountAmount,
    shippingAmount,
    shippingDiscountAmount,
    sellerAmount
  ) {
    return itemsAmount + itemsDiscountAmount + shippingAmount + shippingDiscountAmount - sellerAmount;
  }

  return (
    <>
      <div className="order-detail__card">
        <h3 className="order-detail__card-title">Resumo do pedido</h3>
        {orderChild?.map((child, index) => (
          <div className="order-detail__card-list" key={index}>
            <p className="order-detail__card-item">
              Lojista:
              <span className="order-detail__card-value">{child.seller?.name}</span>
            </p>
            {!isSeller && (
              <>
                <p className="order-detail__card-item">
                  ID lojista:
                  <span className="order-detail__card-value">{child?.seller?.id}</span>
                </p>

                <p className="order-detail__card-item">
                  CNPJ lojista:
                  <span className="order-detail__card-value">{child?.seller?.document}</span>
                </p>
              </>
            )}
            <p className="order-detail__card-item">
              UUID:
              <span className="order-detail__card-value">{child.uuid || ''}</span>
            </p>
            <p className="order-detail__card-item">
              Short ID:
              <span className="order-detail__card-value">{child?.shortCode || ''}</span>
            </p>
            <p className="order-detail__card-item">
              Total dos produtos:
              <span className="order-detail__card-value">
                {FormatHelper.format(child.itemsAmount + child.itemsDiscountAmount, 'currency')}
              </span>
            </p>
            <p className="order-detail__card-item">
              Frete:
              <span className="order-detail__card-value">
                {FormatHelper.format(child.shippingAmount + child.shippingDiscountAmount, 'currency')}
              </span>
            </p>
            {isSeller && (
              <p className="order-detail__card-item">
                Desconto:
                {child.itemsDiscountAmount > 0 ? (
                  <span
                    onClick={`${isSeller ? e => seeDiscount(e, index) : null}`}
                    className={`${isSeller ? 'order-detail__card-value' : ''}`}
                  >
                    {FormatHelper.format(
                      handleDiscountSeller(
                        child?.itemsAmount || 0,
                        child?.itemsDiscountAmount || 0,
                        child?.shippingAmount || 0,
                        child?.shippingDiscountAmount || 0,
                        child?.sellerAmount || 0
                      ),
                      'currency'
                    )}
                  </span>
                ) : (
                  <span className="order-detail__card-value">R$ 0,00</span>
                )}
              </p>
            )}
            {!isSeller && (
              <p className="order-detail__card-item">
                GMV:
                <span className="order-detail__card-value">{FormatHelper.format(child?.sellerAmount, 'currency')}</span>
              </p>
            )}
            {child.owner && (
              <p className="order-detail__card-item">
                Executivo:
                <span className="order-detail__card-value">{child.owner}</span>
              </p>
            )}
          </div>
        ))}
      </div>
      {modal}
    </>
  );
};

export default OrderResume;
